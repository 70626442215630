import '@js/wdyr';

import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import '@style/tailwind.css';
import '@style/globals.scss';
import Router, { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { ProvideAuth } from '@context/UseAuth';
import { ProvideError } from '@context/UseError';
import { ProvideBuilder } from '@context/UseBuilder';
import { appWithTranslation } from 'next-i18next';
import { MuiThemeProvider } from '@material-ui/core';
import store from '@js/store';
import AuthProtectedPage from '@components/AuthProtectedPage';
import Layout from '@components/layouts/Layout';
import BuilderHeader from '@components/builder/BuilderHeader';
import ResponsiveLayout from '@components/layouts/ResponsiveLayout';
import GlobalHeader from '@components/GlobalHeader';
import GlobalFooter from '@components/GlobalFooter';
import NextNProgress from '@components/common/NextNProgress';
import BuilderFooter from '@components/builder/BuilderFooter';
import GoogleAnalytics from '@components/GoogleAnalytics';

import 'overlayscrollbars/css/OverlayScrollbars.min.css';
import { ProvideToast } from '@context/UseToast';
import theme from '@js/muiTheme';
import AcademyOverlay from '@components/builder/academy/AcademyOverlay';
import { AcademyProvider } from '@context/UseAcademy';
import Observability from '@js/utils/honeycomb';
import MockProvider from '@components/layouts/MockProvider';
import { start } from '@js/utils/bugsnag';
import { isLocal } from '@js/helper';

start();

// Add the interceptor to the global axios instance
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (Bugsnag._client && !isLocal) {
      // Check if Bugsnag has started
      Bugsnag.notify(error, (event) => {
        event.addMetadata('axios', {
          url: error.config.url,
          method: error.config.method,
          status: error.response?.status,
          requestData: error.config.data, // Request payload
          responseData: error.response?.data, // Response payload
        });
        event.context = `HTTP ${error.config.method.toUpperCase()} ${
          error.config.url
        }`;
      });
    }

    return Promise.reject(error);
  }
);

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const builderBodyRef = useRef();
  const bodyScroll = useRef();
  const router = useRouter();
  const { pathname } = router;
  const { headerTab, notFound } = pageProps;
  const isBuilderLayout = /\/(builder|my-page)/.test(pathname);
  const isStartLayout =
    /\/(start|sign-up|sign-in|forgot-password|reset-password|verify-email|tell-us-about-you)/.test(
      pathname
    );
  const isLandingPageLayout = !isStartLayout && !isBuilderLayout;

  const routeChangeComplete = () => {
    setTimeout(() => {
      if (builderBodyRef?.current) {
        const { current } = builderBodyRef;
        current.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
      if (bodyScroll?.current) {
        const { current } = bodyScroll;
        current.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
      window.scroll({
        top: 0,
        left: 0,
      });
    }, 1);
  };

  useEffect(() => {
    Router.events.on('routeChangeComplete', routeChangeComplete);

    if (pathname.startsWith('/docs')) {
      document.body.classList.add('docs');
    } else {
      document.body.classList.remove('docs');
    }

    return () => {
      window.scroll({
        top: 0,
        left: 0,
      });
      if (builderBodyRef?.current) {
        const { current } = builderBodyRef;
        current.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
      if (bodyScroll?.current) {
        const { current } = bodyScroll;
        current.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }
      Router.events.off('routeChangeComplete', routeChangeComplete);
    };
  }, [pathname]);

  if (pathname.startsWith('/iframes')) {
    return (
      <Provider store={store}>
        <CookiesProvider>
          <MockProvider>
            <ProvideBuilder headerTab={headerTab}>
              <ResponsiveLayout>
                <MuiThemeProvider theme={theme}>
                  <Component {...pageProps} />
                </MuiThemeProvider>
              </ResponsiveLayout>
            </ProvideBuilder>
          </MockProvider>
        </CookiesProvider>
      </Provider>
    );
  }

  return (
    <>
      <Provider store={store}>
        <CookiesProvider>
          <MockProvider>
            <ProvideAuth>
              <ProvideToast>
                <AcademyProvider>
                  <ProvideError>
                    <GoogleAnalytics />
                    <Observability />
                    {isLandingPageLayout && (
                      <Layout>
                        <ResponsiveLayout>
                          <MuiThemeProvider theme={theme}>
                            <GlobalHeader />
                            <div className="me-body">
                              <div ref={bodyScroll} className="me-body-scroll">
                                <Component {...pageProps} />
                                <GlobalFooter path={pathname} />
                              </div>
                            </div>
                          </MuiThemeProvider>
                        </ResponsiveLayout>
                      </Layout>
                    )}
                    {isStartLayout && (
                      <Layout>
                        <MuiThemeProvider theme={theme}>
                          <div ref={bodyScroll} className="me-body-scroll">
                            <Component {...pageProps} />
                          </div>
                        </MuiThemeProvider>
                      </Layout>
                    )}
                    {isBuilderLayout && (
                      <SessionProvider session={session}>
                        <ProvideBuilder headerTab={headerTab}>
                          <AuthProtectedPage>
                            <Layout>
                              <MuiThemeProvider theme={theme}>
                                <div
                                  id="builder-layout"
                                  className="builder-layout"
                                >
                                  <BuilderHeader />
                                  <AcademyOverlay />
                                  <div
                                    ref={builderBodyRef}
                                    className="builder-body"
                                    id="_builder"
                                  >
                                    <Component {...pageProps} />
                                  </div>
                                  <BuilderFooter />
                                </div>
                                <NextNProgress
                                  stopDelayMs={200}
                                  options={{
                                    showSpinner: false,
                                  }}
                                />
                              </MuiThemeProvider>
                            </Layout>
                          </AuthProtectedPage>
                        </ProvideBuilder>
                      </SessionProvider>
                    )}
                  </ProvideError>
                </AcademyProvider>
              </ProvideToast>
            </ProvideAuth>
          </MockProvider>
        </CookiesProvider>
        <style jsx>
          {`
            @import './src/sass/_vars.scss';
            @import './src/sass/_mixins.scss';

            $transition: 0.3s;
            .builder-layout {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              background: #f2f4f5;
              overflow: hidden;
              transition: all $transition;
              padding-left: 56px;
            }

            .builder-body {
              flex: 1;
              background-color: rgba(244, 244, 244, 0.2);
              overflow-x: auto;
              overflow-y: auto;
              -webkit-overflow-scrolling: auto;
            }
          `}
        </style>
        <style jsx global>
          {`
            //html,
            //body {
            //  width: 100%;
            //  height: 100%;
            //  padding: 0;
            //  margin: 0;
            //  overflow: hidden;
            //}
            //#__next {
            //  width: 100%;
            //  height: 100%;
            //}

            .docs-right {
              overflow: hidden;
              background: #ffffff;

              .mind-body {
                min-height: 100%;
              }

              .mind-content {
                overflow: hidden;
              }

              .docs-content-scroll {
                width: 100%;
                height: 100%;
              }
            }

            .me-body {
              // height: 100%;
              opacity: 1;
            }
            //
            //.me-body-scroll {
            //  height: 100%;
            //  overflow: auto;
            //}

            .os-theme-dark
              > .os-scrollbar
              > .os-scrollbar-track
              > .os-scrollbar-handle {
              background: rgba(0, 0, 0, 0.4);
              transition: none;
              &.active,
              &.active:hover,
              &:hover {
                background: rgba(0, 0, 0, 0.4);
                transition: none;
              }
            }
          `}
        </style>
      </Provider>
    </>
  );
};

export default appWithTranslation(MyApp);
