import rs from 'randomstring';

export const WARNING_POPUP_TYPE = {
  OK: 'OK',
  CONFIRM: 'CONFIRM',
};

export const ITEM_TYPES = {
  EMPTY: 'EMPTY',
  DEPENDENCY: 'DEPENDENCY',
  WEBHOOK: 'WEBHOOK',
};

export const SUPPORT_EMAIL = 'me-support@mind.ai';

// const protocol = process.env.NODE_ENV === 'production' ? 'http' : 'http';
const apiUrl = ''; // (process.browser) ? `${protocol}://${window.location.host}` : `${protocol}://localhost`;

const host = process.env.NODE_ENV === 'production' ? `${apiUrl}` : '';
const urlPrefix = `${host}/api/v1`;
const urlPrefixV2 = `${host}/api/v2`;

export const API_URLS = {
  TIMEZONE: `${urlPrefix}/timezone/`,
  GOOGLE_PATH: `${urlPrefix}/developer/oauth2/google/`,
  GITHUB_PATH: `${urlPrefix}/developer/oauth2/github/`,
  LOGOUT: `${urlPrefix}/developer/logout/`,
  AGREE_POLICY: `${urlPrefix}/developer/has_agreed/`,

  ORGANIZATIONS: `${urlPrefixV2}/organizations/`,
  DEVELOPER: `${urlPrefix}/developer/me/`,
  REGISTER: `${urlPrefix}/developer/register/`,
  LOGIN: `${urlPrefix}/developer/login/`,
  CHANGE_PASSWORD: `${urlPrefix}/developer/change_password/`,
  SEND_VERIFICATION_EMAIL: `${urlPrefix}/developer/send_verification_email/`,
  VERIFY_EMAIL: `${urlPrefix}/developer/verify_email/`,
  INQUIRY: `${urlPrefixV2}/inquiry/`,
  DEVELOPER_SUBSCRIPTION: (devId) =>
    `${urlPrefix}/developer/${devId}/subscription/`,
  DEVELOPER_PROFILE_IMAGE: `${urlPrefix}/developer/me/profile_image_url/`,
  NEWSLETTER_SUBSCRIPTION: `${urlPrefix}/subscribe/`,
  SCOPE: `${urlPrefix}/scope/`,
  SUBJECT: `${urlPrefix}/subject/`,
  SUBSCRIPTION_CONSULTING: `${urlPrefix}/subscription_consulting/`,
  DEPENDENCY: `${urlPrefix}/relation/`,
  PROBLEM: `${urlPrefix}/problem/`,
  CONVERSATION: `${urlPrefix}/conversation/`,
  CONVERSATION_ASYNC: `${urlPrefixV2}/conversation/`,
  BACKUP: `${urlPrefix}/backup/`,
  CONTACT: `${urlPrefix}/contact_us/`,
  KNOWLEDGE: `${urlPrefix}/developer/`,
  KNOWLEDGE_USAGE: `${urlPrefix}/developer/entity_type/usage/`,
  REPORT_DEFINITION: `${urlPrefix}/lf_report/`,
  REPORT_SANDBOX_ERR: `${urlPrefix}/wh_report/`,
  DOCS_FEEDBACK: `${urlPrefix}/feedback/`,
  ANALYTICS: `${urlPrefix}/analytics/`,
  TASKS: `${urlPrefix}/tasks/`,
  CONFIGURATIONS: `${urlPrefix}/config/read_params/`,
  ONTOLOGY: `${urlPrefix}/ontology/`,
  MODIFY_ONTOLOGY: `${urlPrefix}/engine_call/ontology/`,
  ONTOLOGY_USAGE: `${urlPrefix}/developer/ontology/usage/`,
  PREBUILT_SCOPE: `${urlPrefix}/prebuilt_scope/`,
  PREBUILT_SCOPE_V2: `${urlPrefixV2}/prebuilt_scope/`,
  GATEWAY: `${urlPrefix}/gateway/`,
  GATEWAY_V2: `${urlPrefixV2}/gateway/`,

  TARGET_MESSAGE: `${urlPrefixV2}/target_messaging/`,
  ON_BOARDING: `${urlPrefixV2}/onboarding/`,
  SCOPE_V2: `${urlPrefixV2}/scope/`,
  SEND_REQUEST_DEMO: `${urlPrefixV2}/request_demo/send_request_demo/`,
  CHANNELS: `${urlPrefixV2}/channels/`,
  WHATSAPP: `${urlPrefixV2}/whatsapp/`,
  DISCORD: `${urlPrefixV2}/discord/connection/`,
  ONTOLOGY_CSV_IMPORT: `${urlPrefixV2}/ontology_csv/import/`,
  ANALYTICS_V2: `${urlPrefixV2}/analytics/`,
  GOOGE_INTEGRATION: `${urlPrefixV2}/g/connection/`,
  SEND_REQUEST_SENTIMENT: `${urlPrefixV2}/request_sentiment/send_request_sentiment/`,

  // ENGINE call API
  ENGINE: `${urlPrefix}/engine_call/`,
  ENGINE_CALL: `${urlPrefix}/engine_call/get_logical_form/`,
  ENGINE_CALL_LIST: `${urlPrefix}/engine_call/get_related_logical_forms/`,
  ENGINE_CALL_RECOMMENDED: `${urlPrefix}/engine_call/get_suggested_logical_forms/`,
  ENGINE_VALIDATE_CANONICAL: `${urlPrefix}/engine_call/validate_canonical/`,
  ENGINE_VALIDATE_DUPLICATED_ONTOLOGY: `${urlPrefix}/engine_call/validate_duplicate/`,
};

export const URLS = {
  DOCS: 'https://mindx-docs.mind.ai',
};

export const HTTP_STATUS = {
  TERMINATED: 220,
  GREETING: 201,
  FALLBACK: 204,
};

export const WEB_WIDGETS = {
  EN: {
    scopeKey: 'KHwSfhZlTf-ljUrPH-ypxA',
    devKey: 'sZetacGPTC2DMssELa8g9Q',
  },
  TH: {
    scopeKey: 'yJrt8ooqSdCR_21iEOK-Pg',
    devKey: 'sZetacGPTC2DMssELa8g9Q',
  },
};

export const NAV_PAGE = {
  GLOBAL: 'GLOBAL',
  DOCS: 'DOCS',
  SOLUTIONS: 'SOLUTIONS',
  USE_CASES: 'USE_CASES',
  RESOURCES: 'RESOURCES',
};

export const SIGNIN_ERROR_TYPES = {
  GOOGLE: 'google',
  GITHUB: 'github',
  DEACTIVATION: 'deactivation',
  GOOGLE_AUTH: 'google_auth',
  GITHUB_AUTH: 'github_auth',
  COMMON: 'common',
};

export const SUBJECT_TYPE = {
  FS: 'FS', // Query Freestyle Subject, will be renamed to Info search
  IR: 'IR', // ??
  DG: 'DG', // Diagnosis
  IP: 'IP', // Intelligent Process
  QNA: 'QA', // Question Answer
};

export const PROBLEM_TYPE = {
  SUBJECT: 'subject',
  SELECTION_CLASS: 'SC', // Selection Class ||
  SELECTION: 'SW', // Selection |<
  PARAM_BUNDLE: 'PB', // Parameter Bundle {.}}
  PM_REQ: 'PM-RQ', // Parameter Type: Required. {.}
  PM_OPT: 'PM-OP', // Parameter Type: Optional.
  POINT: 'PT', // type condition
  PM_PP: 'PM-PP', // Parameter Type: Principal.
  DG_SELECTION_CLASS: 'DSC',
  DG_SELECTION: 'DSW',
  DG_POINT: 'DPT',
  RESPONSE: 'RS', // Response type component.
};

export const COMPONENT_TYPE = {
  [PROBLEM_TYPE.POINT]: 'Condition',
  [PROBLEM_TYPE.SELECTION_CLASS]: 'Selection Class',
  [PROBLEM_TYPE.SELECTION]: 'Selection',
};

export const CHECK_SOURCE_TYPE = {
  USER: 'USER',
  WEBHOOK: 'WEBHOOK',
  BOTH: 'BOTH',
  TEMPLATE: 'TEMPLATE',
};

export const INVALID_CONDITION = {
  TERMINATE: 'terminate',
  TRY_AGAIN: 'retry',
};

export const NEGATIVE_CONDITION = {
  TERMINATE: 'terminate',
  TRY_AGAIN: 'retry',
};

export const INVALID_MESSAGE_TYPE = {
  HARD: 'hard',
  SOFT: 'soft',
};

export const METHOD_TYPE = {
  GET: 'GET',
  POST: 'POST',
};

export const EDIT_STATE = {
  READ: 'READ',
  DUPLICATE: 'DUPLICATE',
  EDIT: 'EDIT',
};

export const VIEW_OPTIONS = {
  AFFIRMATION: 'AFFIRMATION',
  NEGATION: 'NEGATION',
  DEFINITION: 'DEFINITION',
  RESOLUTION: 'RESOLUTION',
};

export const EXPECTED_RESPONSE = {
  AFFIRMATION: 'affirmation',
  NEGATION: 'negation',
};

export const ACTION_BUTTON_TYPE = {
  HIDDEN: 'HIDDEN',
  CLOSE: 'CLOSE',
  OPEN: 'OPEN',
};

export const ACTIVATION_METHOD = {
  STATEMENT: 'STATEMENT',
  PRINCIPAL_ENTITY: 'PRINCIPAL_ENTITY',
};

export const STATEMENT_TYPES = {
  STATEMENT: 'statement',
  PRINCIPAL: 'principal',
};

export const BUILDER_MIN_WIDTH = 1366;

const regex = /^[a-zA-Z0-9_]*$/gm;
export const KEY_NAME_REGEX = regex; // new RegExp(regex, 'gm');

export const DROPDOWN_LABEL_TYPE = {
  CUSTOM: 'CUSTOM',
};

export const PUBLISH_STATUS = {
  ACTIVATED: 'published',
  DEACTIVATED: 'unpublished',
  UNAVAILABLE: 'unavailable',
};

export const FOCUSED_ACTION = {
  SUBJECT_PUBLISH: 'SUBJECT_PUBLISH',
  CHECK_WITH: 'CHECK_WITH',
  CONFIRMATION: 'CONFIRMATION',
  DG_PT_CHECK_WITH: 'DG_PT_CHECK_WITH',
  SATISFY: 'SATISFY',
  SHOW_RESPONSE: 'SHOW_RESPONSE',
  SOLVE_VERIFY: 'SOLVE_VERIFY',
  DG_POINT_SETTING: 'DG_POINT_SETTING',
  DG_SELECTION_SETTING: 'DG_SELECTION_SETTING',
  PARAMETER_SETTING: 'PARAMETER_SETTING',
  POINT_SETTING: 'POINT_SETTING',
  SELECTION_SETTING: 'SELECTION_SETTING',
  SUBJECT_VALIDATE_POPUP: 'SUBJECT_VALIDATE_POPUP',
  SUBJECT_CLOSING_POPUP: 'SUBJECT_CLOSING_POPUP',
  SUBJECT_MORE_MENU: 'SUBJECT_MORE_MENU',
  STEP_MORE_MENU: 'STEP_MORE_MENU',
  STEP_EDIT_NAME: 'STEP_EDIT_NAME',
  STEP_CLICK: 'STEP_CLICK',
  SC_MORE_MENU: 'SC_MORE_MENU',
  DSC_MORE_MENU: 'DSC_MORE_MENU',
  DPT_MORE_MENU: 'DPT_MORE_MENU',
  PT_MORE_MENU: 'PT_MORE_MENU',
  PB_MORE_MENU: 'PB_MORE_MENU',
  PM_MORE_MENU: 'PM_MORE_MENU',
  PT_EDIT_NAME: 'PT_EDIT_NAME',
  DPT_EDIT_NAME: 'DPT_EDIT_NAME',
  DPT_CLICK: 'DPT_CLICK',
  PT_CLICK: 'PT_CLICK',
  PT_MOVE_CHILDREN: 'PT_MOVE_CHILDREN',
  DPT_MOVE_CHILDREN: 'DPT_MOVE_CHILDREN',
  PM_REQ_CLICK: 'PM_REQ_CLICK',
  PM_OPT_CLICK: 'PM_OPT_CLICK',
  PB_EDIT_NAME: 'PB_EDIT_NAME',
  PB_ADD_CHILDREN: 'PB_ADD_CHILDREN',
  PM_REQ_EDIT_NAME: 'PM_REQ_EDIT_NAME',
  PM_OPT_EDIT_NAME: 'PM_OPT_EDIT_NAME',
  SC_ADD_CHILDREN: 'SC_ADD_CHILDREN',
  DSC_ADD_CHILDREN: 'DSC_ADD_CHILDREN',
  SC_CLICK: 'SC_CLICK',
  DSC_CLICK: 'DSC_CLICK',
  SC_MOVE_CHILDREN: 'SC_MOVE_CHILDREN',
  DSC_MOVE_CHILDREN: 'DSC_MOVE_CHILDREN',
  SC_EDIT_NAME: 'SC_EDIT_NAME',
  DSC_EDIT_NAME: 'DSC_EDIT_NAME',
  SW_DROP_CLICK: 'SW_DROP_CLICK',
  DSW_DROP_CLICK: 'DSW_DROP_CLICK',
  SW_CLICK: 'SW_CLICK',
  DSW_CLICK: 'DSW_CLICK',
  SW_EDIT_NAME: 'SW_EDIT_NAME',
  DSW_EDIT_NAME: 'DSW_EDIT_NAME',
  SW_MORE_MENU: 'SW_MORE_MENU',
  DSW_MORE_MENU: 'DSW_MORE_MENU',
  DELETE_ACTION: 'DELETE_ACTION',
  SET_UP_ACTION_OPEN: 'SET_UP_ACTION_OPEN',
  CONVERSATION_VIEW: 'CONVERSATION_VIEW',
  NS_CONVERSATION_VIEW: 'NS_CONVERSATION_VIEW',
  SUBJECT_VALIDATE_VIEW: 'SUBJECT_VALIDATE_VIEW',
  ADD_COMPONENT: 'ADD_COMPONENT',
  SHOW_SUBJECTS: 'SHOW_SUBJECTS',
  GO_TO_PARENT: 'GO_TO_PARENT',
  CHANGE_VIEW_CLICK: 'CHANGE_VIEW_CLICK',
  REPORT_CLASSIFICATION: 'REPORT_CLASSIFICATION',
  SAVE_CHECK_WITH: 'SAVE_CHECK_WITH',
  SAVE_DG_CHECK_WITH: 'SAVE_DG_CHECK_WITH',
  SAVE_SHOW_RESPONSE: 'SAVE_SHOW_RESPONSE',
  SAVE_SOLVE_VERIFY: 'SAVE_SOLVE_VERIFY',
  SAVE_CONFIRMATION: 'SAVE_CONFIRMATION',
  SAVE_SATISFY: 'SAVE_SATISFY',
  IMPORT_LIBRARY: 'IMPORT_LIBRARY',
};

export const NO_NOTIFIED_ACTIONS = [
  'CANCEL_CHECK_WITH',
  'CANCEL_DG_CHECK_WITH',
  'CANCEL_SHOW_RESPONSE',
  'CANCEL_CONFIRMATION',
  'CANCEL_SOLVE_VERIFY',
  'CANCEL_SATISFY',
];

export const BINDING_KEY_TYPE = {
  LOCAL: 'local',
  GLOBAL: 'global',
};

export const LOADING_STATUS = {
  LOADING: 'LOADING',
  END: 'END',
};

export const LOADING_END_DELAY = 1;

export const PROMPT_COUNT = {
  MIN: 0,
  MAX: 100,
  DEFAULT: 5,
};

export const KW_DESCRIPTION_MAX_LENGTH = 260;
export const CHART_WIDTH = 720;
export const IMPORT_STATUS = {
  DONE: 'DONE',
  PROGRESS: 'PROGRESS',
  QUEUED: 'QUEUED', // Task is queued to be processed
  STARTED: 'STARTED', // Task was started by a worker.
  SUCCESS: 'SUCCESS', // Task successfully done
  FAILURE: 'FAILURE', // Task failed
};

export const SLICE_STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const GLOBAL_NOTIFICATION = {
  KNOWLEDGE_IMPORT: 'KNOWLEDGE_IMPORT',
};

export const FS_INIT_ADD_CONDITION_TYPES = [
  PROBLEM_TYPE.SELECTION_CLASS,
  PROBLEM_TYPE.POINT,
  PROBLEM_TYPE.PARAM_BUNDLE,
];
export const IR_INIT_ADD_CONDITION_TYPES = [
  PROBLEM_TYPE.SELECTION_CLASS,
  PROBLEM_TYPE.PARAM_BUNDLE,
];
export const DG_INIT_ADD_CONDITION_TYPES = [
  PROBLEM_TYPE.DG_POINT,
  PROBLEM_TYPE.DG_SELECTION_CLASS,
  PROBLEM_TYPE.SELECTION_CLASS,
  PROBLEM_TYPE.POINT,
  PROBLEM_TYPE.PARAM_BUNDLE,
];

export const SCROLL_OPTIONS = {
  className: 'os-theme-dark',
  resize: 'none',
  sizeAutoCapable: true,
  paddingAbsolute: true,
  scrollbars: {
    // clickScrolling : true,
    autoHide: 'leave',
  },
  nativeScrollbarsOverlaid: {
    initialize: true,
  },
};

export const SORT_FIELD = {
  NAME: 'NAME',
  STATUS: 'STATUS',
  DATE: 'DATE',
  STATUS_ALL: 'STATUS_ALL',
  STATUS_PUBLISHED: 'STATUS_PUBLISHED',
  STATUS_UNPUBLISHED: 'STATUS_UNPUBLISHED',
  STATUS_UNAVAILABLE: 'STATUS_UNAVAILABLE',
  DATE_LAST_EDIT: 'DATE_LAST_EDIT',
  DATE_CREATED: 'DATE_CREATED',
  NOTE: 'NOTE',
};

export const NAV_TYPE = {
  HIDDEN: 'HIDDEN',
  EXPAND: 'EXPAND',
  COLLAPSE: 'COLLAPSE',
};

export const ANL_VIEW_TYPE = {
  ALL: 'all',
  RESOLVED: 'resolved',
  FALLBACK: 'fallback',
};

export const ANL_DATES_KEY = {
  ALL: 'all',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_3DAY: 'last3',
  LAST_7DAY: 'last7',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
  LAST_3MONTH: 'last3Month',
  THIS_YEAR: 'thisYear',
  CUSTOM: 'custom',
};

export const ANL_METRICS = {
  HOUR: 'hourly',
  DAY: 'daily',
  MONTH: 'monthly',
};

export const STATUS_OPTIONS = [
  {
    key: SORT_FIELD.STATUS_ALL,
    label: 'All',
    value: SORT_FIELD.STATUS_ALL,
  },
  {
    key: SORT_FIELD.STATUS_PUBLISHED,
    label: 'Published',
    value: SORT_FIELD.STATUS_PUBLISHED,
  },
  {
    key: SORT_FIELD.STATUS_UNPUBLISHED,
    label: 'Unpublished',
    value: SORT_FIELD.STATUS_UNPUBLISHED,
  },
];

export const DATE_OPTIONS = [
  {
    key: ANL_DATES_KEY.ALL,
    label: 'All Dates',
    value: 99,
  },
  {
    key: ANL_DATES_KEY.TODAY,
    label: 'Today',
    value: 0,
  },
  {
    key: ANL_DATES_KEY.YESTERDAY,
    label: 'Yesterday',
    value: 1,
  },
  {
    key: ANL_DATES_KEY.LAST_3DAY,
    label: 'Last 3 Days',
    value: 3,
  },
  {
    key: ANL_DATES_KEY.LAST_7DAY,
    label: 'Last 7 Days',
    value: 7,
  },
  {
    key: ANL_DATES_KEY.THIS_MONTH,
    label: 'This Month',
    value: 8,
  },
  {
    key: ANL_DATES_KEY.LAST_MONTH,
    label: 'Last Month',
    value: 9,
  },
  {
    key: ANL_DATES_KEY.LAST_3MONTH,
    label: 'Last 3 Months',
    value: 10,
  },
  {
    key: ANL_DATES_KEY.THIS_YEAR,
    label: 'This Year',
    value: 11,
  },
  {
    key: ANL_DATES_KEY.CUSTOM,
    label: 'Custom',
    value: 'custom',
    labelType: DROPDOWN_LABEL_TYPE.CUSTOM,
  },
];

export const LITERAL_ENTITY_TYPE = {
  GENERAL: 'general',
  PRODUCT: 'product',
};

export const QUICK_MENU_ITEM_FIELDS = {
  id: rs.generate(),
  label: '',
  type: {
    key: 'message',
    label: 'Message',
    value: 'message',
  },
  subject_statement: '',
  link: '',
};

export const INTEGRATION_DEFAULT_SET = {
  line: {
    button_background: '#06C755',
    card_background: '#2A2A2A',
    card_title: '#FFFFFF',
    card_subtitle: '#989898',
  },
  viber: {
    card_title: '#FFFFFF',
    card_subtitle: '#878C97',
    card_background: '#1F232B',
    card_primary_bt_bg: '#735EF1',
    card_primary_bt_text: '#FFFFFF',
    card_secondary_bt_bg: '#1F232B',
    card_secondary_bt_text: '#FFFFFF',
  },
  web_widget: {
    bot_name: '',
    theme: 'light',
    primary_color: '#5848E3',
    background_color: '#F5F5F5',
    header_text_color: '#5848E3',
    header_bg_color: '#FFFFFF',
    res_background_color: '#F2F4F5',
    res_text_color: '#2B2C2D',
    btn_background_color: '#FFFFFF',
    btn_text_color: '#2B2C2D',
    input_text_color: '#FFFFFF',
    input_bg_color: '#5848E3',
    power_by: true,
    icon: '/images/bt_send/bt_send.svg',
    icons: [],
    show_quick_menu: false,
    quick_menu_title: 'Quick Menu',
    quick_menu_items: [{ ...QUICK_MENU_ITEM_FIELDS }],
    show_ai_image: false,
    ai_image: '/images/chat_widget/ai.svg',
    new_message_preview: true,
    scope_name: '',
    branding: 'Mind Expression',
    size: 'normal',
    welcome_msg: 'Hey, how can I help you?',
    widget_position: 'right',
    language: 'en',
    themes: {
      input_text_color: '#fff',
      light: {
        background_color: '#f6f6f7',
        res_background_color: '#fff',
        res_text_color: '#2B2C2D',
        header_text_color: '#fff',
        header_bg_color: '#5848E3',
      },
      dark: {
        background_color: '#2b2c2d',
        res_background_color: '#38393A',
        res_text_color: '#F6F6F7',
        header_text_color: '#fff',
        header_bg_color: '#5848E3',
      },
      custom: {
        background_color: '#f6f6f7',
        res_background_color: '#fff',
        res_text_color: '#2B2C2D',
        header_text_color: '#fff',
        header_bg_color: '#5848E3',
      },
    },
  },
  discord: {
    button_background: '#5A65E9',
  },
};

export const CHANNEL_DISPLAY_ORDER = [
  'default',
  'line',
  'facebook',
  'instagram',
  'viber',
  'web_widget',
  'kakaotalk',
  'discord',
  'whatsapp',
];

export const CHANNEL_INTEGRATION_POPUP_VIEW = {
  SETUP: 'SETUP',
  USED: 'USED',
};

export const RESPONSE_CARD_TITLE_MAX_LENGTH = 70;
export const TEMPLATE_TEXT_MAX_LENGTH = 1000;

export const TEMPLATE_TYPES = {
  TEMPLATE: 'template',
  FREE: 'free',
  CODE: 'code',
  CHANNELS: 'channels',
  WHATSAPP: 'whatsapp_template',
  DATE: 'datetime',
  TIME_BASED_TEMPLATE: 'timeframe',
  MULTIPLE: 'multiple',
};

export const CARD_CONTENT = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const DEF_TEMPLATE_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  CARD: 'card',
  QUICK: 'quick_reply',
  WHATSAPP: 'whatsapp_template',
  DATE: 'datetime',
  VIDEO: 'video',
  DOCUMENT: 'document',
};

export const TEMPLATE_CHANNEL_DISPLAY = {
  DEFAULT: {
    key: 'default',
    label: 'Mind Expression API',
    value: 'default',
  },
  DEFAULT_WEBHOOK: {
    key: 'default_webhook',
    label: 'Mind Expression API / Server',
    value: 'default_webhook',
  },
  ASYNC_WEB_SOCKET: {
    key: 'async_web_socket',
    label: 'Mind Expression API / Client',
    value: 'async_web_socket',
  },
  LINE: {
    key: 'line',
    label: 'LINE',
    value: 'line',
  },
  LINE_DESKTOP: {
    key: 'line_desktop',
    label: 'LINE (Desktop)',
    value: 'line_desktop',
  },
  VIBER: {
    key: 'viber',
    label: 'Viber',
    value: 'viber',
  },
  FACEBOOK: {
    key: 'facebook',
    label: 'Facebook Messenger',
    value: 'facebook',
  },
  INSTAGRAM: {
    key: 'instagram',
    label: 'Instagram',
    value: 'instagram',
  },
  CHAT_WIDGET: {
    key: 'web_widget',
    label: 'Chat Widget',
    value: 'web_widget',
  },
  KAKAOTALK: {
    key: 'kakaotalk',
    label: 'KakaoTalk',
    value: 'kakaotalk',
  },
  DISCORD: {
    key: 'discord',
    label: 'Discord',
    value: 'discord',
  },
  WHATSAPP: {
    key: 'whatsapp',
    label: 'WhatsApp',
    value: 'whatsapp',
  },
};

export const TEMPLATE_CHANNEL_LIVE_CHAT = {
  LINE: {
    key: 'line',
    label: 'LINE',
    value: 'line',
  },
  VIBER: {
    key: 'viber',
    label: 'Viber',
    value: 'viber',
  },
  FACEBOOK: {
    key: 'facebook',
    label: 'Facebook Messenger',
    value: 'facebook',
  },
  INSTAGRAM: {
    key: 'instagram',
    label: 'Instagram',
    value: 'instagram',
  },
  WEB_WIDGET: {
    key: 'web_widget',
    label: 'Chat Widget',
    value: 'web_widget',
  },
  WHATSAPP: {
    key: 'whatsapp',
    label: 'WhatsApp',
    value: 'whatsapp',
  },
};

const EXTERNAL_CHANNELS = [
  {
    key: 'line',
    label: 'LINE',
    value: 'line',
  },
  {
    key: 'viber',
    label: 'Viber',
    value: 'viber',
  },
  {
    key: 'discord',
    label: 'Discord',
    value: 'discord',
  },
  {
    key: 'facebook',
    label: 'Facebook Messenger',
    value: 'facebook',
  },
  {
    key: 'instagram',
    label: 'Instagram',
    value: 'instagram',
  },
  {
    key: 'kakaotalk',
    label: 'KakaoTalk',
    value: 'kakaotalk',
  },
  {
    key: 'web_widget',
    label: 'Chat Widget',
    value: 'web_widget',
  },
  {
    key: 'whatsapp',
    label: 'WhatsApp',
    value: 'whatsapp',
  },
];

export const TEMPLATE_CHANNEL_LIST = [
  {
    key: 'default',
    label: 'Mind Expression API',
    value: 'default',
  },
].concat(EXTERNAL_CHANNELS);

export const SESSION_HISTORY_CHANNEL_LIST = [
  {
    key: 'default',
    label: 'Mind Expression API / Server',
    value: 'default',
  },
  {
    key: 'default_webhook',
    label: 'Mind Expression API / Server',
    value: 'default_webhook',
  },
  {
    key: 'async_web_socket',
    label: 'Mind Expression API / Client',
    value: 'async_web_socket',
  },
].concat(EXTERNAL_CHANNELS);

if (process.env.NEXT_PUBLIC_ENABLE_DISCORD === 'true') {
  EXTERNAL_CHANNELS.push({
    key: 'discord',
    label: 'Discord',
    value: 'discord',
  });
}

export const ALTERNATIVE_TEMPLATES = {
  text: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
  image: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
  card: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
  video: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
  document: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
  quick_reply: [
    TEMPLATE_CHANNEL_DISPLAY.DEFAULT,
    TEMPLATE_CHANNEL_DISPLAY.VIBER,
    TEMPLATE_CHANNEL_DISPLAY.LINE_DESKTOP,
  ],
  date: [TEMPLATE_CHANNEL_DISPLAY.DEFAULT],
};

export const DESC_ALTERNATIVE_TEMPLATES = {
  text: [],
  image: [],
  video: [],
  document: [],
  card: [],
  quick_reply: [
    {
      key: 'viber',
      label: 'Viber',
      value: 'viber',
    },
    {
      key: 'line',
      label: 'LINE Desktop',
      value: 'line_desktop',
    },
  ],
  date: [],
};

export const FREE_CHANNELS = [
  TEMPLATE_CHANNEL_DISPLAY.DEFAULT,
  TEMPLATE_CHANNEL_DISPLAY.LINE,
  TEMPLATE_CHANNEL_DISPLAY.INSTAGRAM,
  TEMPLATE_CHANNEL_DISPLAY.FACEBOOK,
  TEMPLATE_CHANNEL_DISPLAY.VIBER,
  TEMPLATE_CHANNEL_DISPLAY.KAKAOTALK,
];

export const AMPM_VALUES = {
  PM: {
    key: 'pm',
    label: 'PM',
    value: 'pm',
  },
  AM: {
    key: 'am',
    label: 'AM',
    value: 'am',
  },
};

export const DATE_PICKER_LANGUAGE_OPTIONS = [
  {
    key: 'en',
    label: 'English',
    value: 'en',
  },
  {
    key: 'ko',
    label: 'Korean',
    value: 'ko',
  },
  {
    key: 'th',
    label: 'Thai',
    value: 'th',
  },
];

export const DATE_PICKER_LABELS = {
  EN: {
    selected_date: 'Selected Date',
    selected_time: 'Selected Time',
    time: 'Time',
    start_date: 'Start Date',
    end_date: 'End Date',
    error: {
      select_date: 'Please select a date',
      select_time: 'Please select a valid time',
      select_date_range: 'Please select a valid date range',
    },
    am: 'AM',
    pm: 'PM',
  },
  KO: {
    selected_date: '선택된 날짜',
    selected_time: '선택된 시간',
    time: '시간',
    start_date: '시작일',
    end_date: '종료일',
    error: {
      select_date: '날짜를 선택해 주세요.',
      select_time: '시간을 선택해 주세요.',
      select_date_range: '시작일과 종료일을 모두 선택해 주세요.',
    },
    am: '오전',
    pm: '오후',
  },
  TH: {
    selected_date: 'วันที่ที่เลือก',
    selected_time: 'เวลาที่เลือก',
    time: 'เวลา',
    start_date: 'วันที่เริ่มต้น',
    end_date: 'วันที่สิ้นสุด',
    error: {
      select_date: 'โปรดเลือกวันที่',
      select_time: 'โปรดเลือกเวลาที่ถูกต้อง',
      select_date_range: 'โปรดเลือกช่วงวันที่ที่ถูกต้อง',
    },
    am: 'AM',
    pm: 'PM',
  },
};

export const DATE_FORMAT_OPTIONS = {
  date: [
    {
      key: 'yyyy/MM/dd',
      label: 'YYYY/MM/DD',
      value: 'YYYY/MM/DD',
    },
    {
      key: 'MM/dd/yyyy',
      label: 'MM/DD/YYYY',
      value: 'MM/DD/YYYY',
    },
    {
      key: 'dd/MM/yyyy',
      label: 'DD/MM/YYYY',
      value: 'DD/MM/YYYY',
    },
  ],
  time: [
    {
      key: 'hh:mm a',
      label: 'HH:MM AM/PM',
      value: 'HH:MM AM/PM',
    },
    {
      key: '오전/오후 HH:MM',
      label: '오전/오후 HH:MM',
      value: '오전/오후 HH:MM',
    },
    {
      key: 'HH:mm',
      label: 'HH:MM',
      value: 'HH:MM',
    },
  ],
  datetime: [
    {
      key: 'YYYY/MM/DD, HH:MM AM/PM',
      label: 'YYYY/MM/DD, HH:MM AM/PM',
      value: 'YYYY/MM/DD, HH:MM AM/PM',
    },
    {
      key: 'YYYY/MM/DD, HH:MM',
      label: 'YYYY/MM/DD, HH:MM',
      value: 'YYYY/MM/DD, HH:MM',
    },
    {
      key: 'YYYY/MM/DD, 오전/오후 HH:MM',
      label: 'YYYY/MM/DD, 오전/오후 HH:MM',
      value: 'YYYY/MM/DD, 오전/오후 HH:MM',
    },
    {
      key: 'MM/DD/YYYY, HH:MM AM/PM',
      label: 'MM/DD/YYYY, HH:MM AM/PM',
      value: 'MM/DD/YYYY, HH:MM AM/PM',
    },
    {
      key: 'MM/DD/YYYY, HH:MM',
      label: 'MM/DD/YYYY, HH:MM',
      value: 'MM/DD/YYYY, HH:MM',
    },
    {
      key: 'DD/MM/YYYY, HH:MM AM/PM',
      label: 'DD/MM/YYYY, HH:MM AM/PM',
      value: 'DD/MM/YYYY, HH:MM AM/PM',
    },
    {
      key: 'DD/MM/YYYY, HH:MM',
      label: 'DD/MM/YYYY, HH:MM',
      value: 'DD/MM/YYYY, HH:MM',
    },
  ],
  date_range: [
    {
      key: 'yyyy/MM/dd',
      label: 'YYYY/MM/DD - YYYY/MM/DD',
      value: 'YYYY/MM/DD',
    },
    {
      key: 'MM/dd/yyyy',
      label: 'MM/DD/YYYY - MM/DD/YYYY',
      value: 'MM/DD/YYYY',
    },
    {
      key: 'dd/MM/yyyy',
      label: 'DD/MM/YYYY - DD/MM/YYYY',
      value: 'DD/MM/YYYY',
    },
  ],
};

export const CHANNEL_IMAGES = {
  DEFAULT: {
    img: '/images/ic_default.svg',
    name: 'DEFAULT',
  },
  DEFAULT_WEBHOOK: {
    img: '/images/ic_default.svg',
    name: 'DEFAULT',
  },
  ASYNC_WEB_SOCKET: {
    img: '/images/ic_default.svg',
    name: 'DEFAULT',
  },
  LINE: {
    img: '/images/ic_line.svg',
    name: 'LINE',
  },
  FACEBOOK: {
    img: '/images/ic_messenger.svg',
    name: 'Facebook Messenger',
  },
  INSTAGRAM: {
    img: '/images/ic_instagram.png',
    name: 'Instagram',
  },
  VIBER: {
    img: '/images/ic_viber.svg',
    name: 'Viber',
  },
  KAKAOTALK: {
    img: '/images/ic_kakaotalk.svg',
    name: 'KakaoTalk',
  },
  WEB_WIDGET: {
    img: '/images/ic_chatbot.svg',
    name: 'Chat Widget',
  },
  CHAT_WIDGET: {
    img: '/images/ic_chatbot.svg',
    name: 'Chat Widget',
  },
  DISCORD: {
    img: '/images/ic_discord.svg',
    name: 'Discord',
  },
  WHATSAPP: {
    img: '/images/ic_whatsapp.png',
    name: 'Whatsapp',
  },
};

export const DEF_TEMPLATE_VAlUE = {
  'template-type': DEF_TEMPLATE_TYPES.TEXT,
  template: '',
};

export const DEF_MULTI_TEMPLATE_VALUE = {
  'template-type': DEF_TEMPLATE_TYPES.TEXT,
  content: [''],
};

export const DEF_TIME_BASED_TEMPLATE_VALUE = {
  start_time: null,
  end_time: null,
  timezone: '',
  message_list: [DEF_MULTI_TEMPLATE_VALUE],
  'template-type': 'multiple',
};

export const DEF_ALTERNATIVE_VALUE = {
  'selected-channel': 'default',
  channels: [
    {
      'channel-name': 'default',
      'template-type': 'text',
      template: '',
    },
  ],
};

export const DEF_SAVE_ALT_VALUE = {
  'selected-channel': 'default',
  channels: [],
};

export const IMAGE_REGEX = /(https:\/\/.*\.(?:png|jpg|jpeg))/;
export const URL_REGEX =
  /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$)|(^(http:\/\/|https:\/\/)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/.*)?$)/;

export const RELATIONS_TYPE_VALUES = {
  SPECIFIC: 'It is a more specific form of the primary statement',
  GENERAL: 'It is a more general form of the primary statement',
  CAUSE: 'It is a cause of what is said in the primary statement',
  EFFECT: 'It is an effect of what is said in the primary statement',
  DESIRED_RESULT: 'Desired Result',
};

export const RELATION_DEFINITION = {
  SPECIFIC: 'Specific',
  GENERAL: 'General',
  CAUSE: 'Cause',
  EFFECT: 'Effect',
  DESIRED_RESULT: 'Desired Result',
};

export const RELATION_DEFINITION_TO_VALUES = {
  [RELATION_DEFINITION.SPECIFIC]: RELATIONS_TYPE_VALUES.SPECIFIC,
  [RELATION_DEFINITION.GENERAL]: RELATIONS_TYPE_VALUES.GENERAL,
  [RELATION_DEFINITION.CAUSE]: RELATIONS_TYPE_VALUES.CAUSE,
  [RELATION_DEFINITION.EFFECT]: RELATIONS_TYPE_VALUES.EFFECT,
  [RELATION_DEFINITION.DESIRED_RESULT]: RELATIONS_TYPE_VALUES.DESIRED_RESULT,
};

export const RELATION_DEFINITION_TO_TITLES = {
  [RELATION_DEFINITION.SPECIFIC]: 'More specific form',
  [RELATION_DEFINITION.GENERAL]: 'More general form',
  [RELATION_DEFINITION.CAUSE]: 'Cause/implies',
  [RELATION_DEFINITION.EFFECT]: 'Effect/entails',
  [RELATION_DEFINITION.DESIRED_RESULT]: RELATIONS_TYPE_VALUES.DESIRED_RESULT,
};

export const RELATION_TYPE_VALUES_TO_DEFINITION = {
  [RELATIONS_TYPE_VALUES.SPECIFIC]: RELATION_DEFINITION.SPECIFIC,
  [RELATIONS_TYPE_VALUES.GENERAL]: RELATION_DEFINITION.GENERAL,
  [RELATIONS_TYPE_VALUES.CAUSE]: RELATION_DEFINITION.CAUSE,
  [RELATIONS_TYPE_VALUES.EFFECT]: RELATION_DEFINITION.EFFECT,
  [RELATIONS_TYPE_VALUES.DESIRED_RESULT]: RELATION_DEFINITION.DESIRED_RESULT,
};

export const RELATION_DEFINITION_DESIRED_RESULT = 'Desired Result';

export const RELATIONS_TYPES = [
  {
    key: 'divider',
    label: 'Hierarchical Relations',
    value: null,
  },
  {
    key: 'It is a more specific form of the primary statement',
    label: 'More specific form',
    desc: 'Relays a more detailed description of the primary statement',
    value: 'It is a more specific form of the primary statement',
    labelType: DROPDOWN_LABEL_TYPE.CUSTOM,
    type: 'hierarchical',
  },
  {
    key: 'It is a more general form of the primary statement',
    label: 'More general form',
    desc: 'Relays a less detailed description of the primary statement',
    value: 'It is a more general form of the primary statement',
    labelType: DROPDOWN_LABEL_TYPE.CUSTOM,
    type: 'hierarchical',
  },
  {
    key: 'divider',
    label: 'Logical Relations',
    value: null,
  },
  {
    key: 'It is a cause of what is said in the primary statement',
    label: 'Cause/implies',
    desc: 'Describes a potential condition for the primary statement to be true',
    value: 'It is a cause of what is said in the primary statement',
    labelType: DROPDOWN_LABEL_TYPE.CUSTOM,
    type: 'logical',
  },
  {
    key: 'It is an effect of what is said in the primary statement',
    label: 'Effect/entails',
    desc: 'Describes a potential consequence of the primary statement’s truth',
    value: 'It is an effect of what is said in the primary statement',
    labelType: DROPDOWN_LABEL_TYPE.CUSTOM,
    type: 'logical',
  },
];

export const ACADEMY_MODE = {
  // Create scope lesson
  START_CREATE_SCOPE: 'start-create-scope',
  CREATE_SCOPE: 'create-scope',
  SCOPE_MODE: 'scope-mode',
  SCOPE_NAME: 'scope-name',
  COMPLETE_CREATE_SCOPE: 'complete-create-scope',

  // Build subject lesson
  // Continue steps
  LETS_BUILD_SUBJECT: 'let-build-subject',
  LETS_TEST_SUBJECT: 'let-test-subject',

  START_ADD_SUBJECT: 'start-add-subject',
  // Part 1
  SCOPE: 'scope',
  ADD_SUBJECT: 'add-subject',
  SUBJECT_TYPE: 'subject-type',
  SUBJECT_NAME: 'subject-name',
  SETUP_QUERY_STATEMENT: 'setup-query-statement',
  QUERY_STATEMENT: 'query-statement',
  RECOGNIZE_STATEMENT: 'recognize-statement',
  RECOGNIZE_STATEMENT_SUBMIT: 'recognize-statement-submit',
  SUBJECT_SUBMIT: 'subject-submit',
  // Part 2
  ADD_ACTION: 'add-action',
  SHOW_RESPONSE_LOCAL: 'show-response-local',
  RESPONSE_TEMPLATES: 'response-templates',
  ENTER_RESPONSE: 'enter-response',
  TEMPLATE_ALTERNATIVE: 'template-alternative',
  SUBJECT_PUBLISH: 'subject-publish',
  // Part 3
  OPEN_SANDBOX: 'open-sandbox',
  TEST_CONVERSATION: 'test-conversation',
  AI_RESPONSE: 'ai-response',
  COMPLETE_BUILD_SUBJECT: 'complete-build-subject',
};

export const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const YOUTUBE_MIND_AI_PLAYLIST =
  'https://www.youtube.com/channel/UCAmYDodFoCtdTPM_5NBgO5w';

export const INVALID_WEBHOOK_KEYS = ['authorization'];

export const ROLES = {
  ADMIN: 'admin',
  AI_DESIGNER: 'designer',
  ANALYST: 'analyst',
  HUMAN_AGENT: 'agent',
};

export const SUBJECT_SAVE_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

const index = {
  WARNING_POPUP_TYPE,
  ITEM_TYPES,
  API_URLS,
  NAV_PAGE,
  INVALID_CONDITION,
  NEGATIVE_CONDITION,
  INVALID_MESSAGE_TYPE,
  METHOD_TYPE,
  PROBLEM_TYPE,
  EDIT_STATE,
  SUBJECT_TYPE,
  CHECK_SOURCE_TYPE,
  VIEW_OPTIONS,
  ACTION_BUTTON_TYPE,
  EXPECTED_RESPONSE,
  BUILDER_MIN_WIDTH,
  KEY_NAME_REGEX,
  DROPDOWN_LABEL_TYPE,
  FOCUSED_ACTION,
  PUBLISH_STATUS,
  NO_NOTIFIED_ACTIONS,
  BINDING_KEY_TYPE,
  LOADING_STATUS,
  LOADING_END_DELAY,
  CHART_WIDTH,
  IMPORT_STATUS,
  SCROLL_OPTIONS,
  SUBJECT_SAVE_TYPE,
};

export default index;
