import { configureStore } from '@reduxjs/toolkit';
import removeContextReducer from '@js/store/slices/removeContextSlice';
import knowledgeReducer from './slices/knowledgeSlice';
import conditionReducer from './slices/conditionsSlice';
import conversationReducer from './slices/conversationSlice';
import referenceReducer from './slices/referenceSlice';
import calendarReducer from './slices/caldendarSlice';
import sandboxReducer from './slices/sandboxSlice';
import builderReducer from './slices/builderSlice';
import flowNodesSlice from './slices/flowNodesSlice';
import refSubjectNodesReducer from './slices/refSubjectNodesSlice';

export default configureStore({
  reducer: {
    builder: builderReducer,
    calendar: calendarReducer,
    condition: conditionReducer,
    conversation: conversationReducer,
    knowledge: knowledgeReducer,
    reference: referenceReducer,
    refSubjectNodes: refSubjectNodesReducer,
    removeContext: removeContextReducer,
    sandbox: sandboxReducer,
    flowNodes: flowNodesSlice,
  },
  devTools: true,
});
