import handlers from './handlers';

export const mswWorker = async () => {
  if (typeof window !== 'undefined') {
    const { setupWorker } = require('msw/browser');

    const worker = setupWorker(...handlers);

    return worker;
  }
};