import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import axios from 'axios';
import Cookies from 'universal-cookie';
import { getLayoutedElements } from '@components/builder/subject/flow/helper';
import { PROBLEM_TYPE, SLICE_STATUSES } from '@js/constants';

export const fetchNodes = createAsyncThunk(
  'flowNodes/fetchNodes',
  async ({
    scopeId,
    subjectId,
    stepUrlKey,
    errorCallback,
    libraryId,
    isLibrary = false,
  }) => {
    const cook = new Cookies();
    const getToken = cook.get('m_e_t');

    const url = isLibrary
      ? `/api/v2/scope/${scopeId}/library/${libraryId}`
      : `/api/v2/scope/${scopeId}/subject/${subjectId}/${stepUrlKey}/`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      return response.data;
    } catch (e) {
      if (errorCallback) {
        errorCallback(e);
      }

      if (
        e?.response?.status === 404 &&
        e?.response?.data?.message === 'Component not found'
      ) {
        return { data: null };
      }

      throw e;
    }
  }
);

const flowNodesSlice = createSlice({
  name: 'flowNodes',
  initialState: {
    nodes: [],
    edges: [],
    relations: [],
    status: SLICE_STATUSES.IDLE,
    error: null,
  },
  reducers: {
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action) => {
      state.edges = action.payload;
    },
    addNode: (state, action) => {
      state.nodes.push(action.payload);
    },
    resetNodesAndEdges: (state) => {
      state.nodes = [];
      state.edges = [];
    },
    resetStatus: (state) => {
      state.status = SLICE_STATUSES.IDLE;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNodes.pending, (state, action) => {
        state.status = SLICE_STATUSES.LOADING;
      })
      .addCase(fetchNodes.fulfilled, (state, action) => {
        state.status = SLICE_STATUSES.SUCCEEDED;

        if (!action.payload.data) {
          state.nodes = [];
          state.edges = [];
          return;
        }

        // Transform nodes data to support QnA type and response.
        const nodesData = action.payload.data;

        if (nodesData.type === PROBLEM_TYPE.SUBJECT) {
          if (nodesData?.children[0]?.action?.step_sequence) {
            delete nodesData.children[0].action.step_sequence;
          }
          nodesData.action = nodesData?.children[0]?.action;
          delete nodesData.children;
        }

        const { newNodes, newEdges } = getLayoutedElements(
          nodesData,
          true,
          action.payload.relations
        );
        state.nodes = newNodes;
        state.edges = newEdges;
        state.relations = action.payload.relations;
      })
      .addCase(fetchNodes.rejected, (state, action) => {
        state.status = SLICE_STATUSES.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { setNodes, setEdges, resetNodesAndEdges, resetStatus } =
  flowNodesSlice.actions;

export const flowNodes = createSelector(
  (state) => ({
    nodes: state.flowNodes.nodes,
    edges: state.flowNodes.edges,
    relations: state.flowNodes.relations,
    status: state.flowNodes.status,
  }),
  (state) => state
);

export default flowNodesSlice.reducer;
