'use client';

import React, { useEffect, useState } from 'react';

function MockProvider({ children }) {
  const shouldEnableMock = process.env.NEXT_PUBLIC_USE_MSW_MOCK;
  const [shouldMock, setShouldMock] = useState(true);

  useEffect(() => {
    async function enableApiMocking() {
      const { mswWorker } = await require('../../mocks/browser');
      const worker = await mswWorker();
      worker.start({
        onUnhandledRequest: 'bypass',
      });
    }

    if (shouldEnableMock === 'true' && shouldMock) {
      enableApiMocking();
      setShouldMock(false);
    }
  }, []);

  return <>{children}</>;
}

export default MockProvider;
