import { createSlice, createSelector } from '@reduxjs/toolkit';

const builderSlice = createSlice({
  name: 'builder',
  initialState: {
    builderTasks: [],
    builderGlobalNotification: [],
    focusedAction: '',
    loadingBuilder: null, // 'LOADING', 'END'
    hideProgress: false,
    showTellUsPopup: false,
    forceCallGetCurrentTask: [],
    resetFlowPosition: false,
    hoveredNodeGroup: '', // Use for add hover state to all nodes in a group
  },
  reducers: {
    removeGlobalNotification: (state, action) => {
      const { task_id } = action.payload;
      const idx = state.builderGlobalNotification.findIndex(
        (li) => li.task_id === task_id
      );
      if (idx > -1) {
        state.builderGlobalNotification.splice(idx, 1);
      }
    },
    removeAllGlobalNotification: (state, action) => {
      state.builderGlobalNotification = [];
    },
    addGlobalNotification: (state, action) => {
      /**
       * task_id : '',
       * type: '',
       * status: '',
       * content:  '',
       */
      const { task_id } = action.payload;
      const idx = state.builderGlobalNotification.findIndex(
        (g) => g.task_id === task_id
      );
      if (idx > -1) {
        state.builderGlobalNotification[idx] = action.payload;
      } else {
        if (state.builderGlobalNotification.length > 0) {
          state.builderGlobalNotification = [];
        }
        state.builderGlobalNotification.push(action.payload);
      }
    },
    setShowTellUsPopup: (state, action) => {
      state.showTellUsPopup = action.payload;
    },
    setFocusedAction: (state, action) => {
      state.focusedAction = action.payload;
    },
    setHideProgress: (state, action) => {
      state.hideProgress = action.payload;
    },
    setResetFlowPosition: (state, action) => {
      state.resetFlowPosition = action.payload;
    },
    setHoveredNodeGroup: (state, action) => {
      state.hoveredNodeGroup = action.payload;
    },
    setLoadingBuilder: (state, action) => {
      state.loadingBuilder = action.payload;
    },
    addBuilderTask: (state, action) => {
      const { task_id } = action.payload;
      const idx = state.builderTasks.findIndex((bu) => bu.task_id === task_id);
      if (idx > -1) {
        state.builderTasks[idx] = action.payload;
      } else {
        state.builderTasks.push(action.payload);
      }
    },
    removeBuilderTask: (state, action) => {
      const { task_id } = action.payload;
      const idx = state.builderTasks.findIndex((bu) => bu.task_id === task_id);
      if (idx > -1) {
        state.builderTasks.splice(idx, 1);
      }
    },
    removeAllBuilderTasks: (state, action) => {
      state.builderTasks = [];
    },
    setForceCallGetCurrentTask: (state, action) => {
      state.forceCallGetCurrentTask = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  removeGlobalNotification,
  removeAllGlobalNotification,
  addGlobalNotification,
  setShowTellUsPopup,
  setFocusedAction,
  setLoadingBuilder,
  setHideProgress,
  setResetFlowPosition,
  setHoveredNodeGroup,
  addBuilderTask,
  removeAllBuilderTasks,
  setForceCallGetCurrentTask,
} = builderSlice.actions;

export const builder = createSelector(
  (state) => ({
    builderGlobalNotification: state.builder.builderGlobalNotification,
    focusedAction: state.builder.focusedAction,
    loadingBuilder: state.builder.loadingBuilder,
    hideProgress: state.builder.hideProgress,
    resetFlowPosition: state.builder.resetFlowPosition,
    hoveredNodeGroup: state.builder.hoveredNodeGroup,
    builderTasks: state.builder.builderTasks,
    forceCallGetCurrentTask: state.builder.forceCallGetCurrentTask,
    showTellUsPopup: state.builder.showTellUsPopup,
  }),
  (state) => state
);

export default builderSlice.reducer;
